import React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    NumberInput,
    ReferenceField,
    TextField,
    BooleanInput,
    ArrayField,
    Datagrid,
    NumberField,
    SelectInput,
    ShowButton,
    FunctionField,
} from 'react-admin';
import { Done as DoneIcon, Clear as ClearIcon } from '@material-ui/icons';
import { required } from 'ra-core';
import OrderTitle from './OrderTitle';
import cartHelper from '../../helpers/cartHelper';
import ChangeSerialNumberButton from './ChangeSerialNumberButton';
import StatusSelect from './StatusSelect';
import EditOrderToolbar from './EditOrderToolbar';
import DiscountCodeFields from './DiscountCodeFields';
import { SHIPPING_TYPES } from './constants';

const ShippingDataFloorInput = ({ record, ...rest }) => {
    const all = { record, ...rest };
    return record?.shippingData?.shippingType === SHIPPING_TYPES.homeDelivery
        && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextInput source="shippingData.floor" label="Piso" {...all} />
                <NumberInput source="shippingData.number" min="0" label="N&uacute;mero" {...all} />
            </div>
        );
};

const OrderEdit = (props) => (
    <Edit title={<OrderTitle />} {...props}>
        <TabbedForm toolbar={<EditOrderToolbar />}>
            <FormTab label="Main">
                <ReferenceField reference="users" source="buyer" label="Comprador">
                    <TextField source="email" />
                </ReferenceField>
                <StatusSelect />
                <TextInput source="billId" label="N&uacute;mero de factura" />
                <DiscountCodeFields />
            </FormTab>
            <FormTab label="Shipping Data">
                <TextInput source="shippingData.firstName" label="Nombre" />
                <TextInput source="shippingData.lastName" label="Apellido" />
                <TextInput source="shippingData.dni" label="DNI (de quien recibe)" />
                <TextInput source="shippingData.phone" label="Tel&eacute;fono" />
                <SelectInput
                    source="shippingData.province"
                    label="Provincia"
                    choices={cartHelper.getProvinces()}
                />
                <TextInput source="shippingData.locality" label="Localidad" />
                <TextInput source="shippingData.codigoPostal" label="C&oacute;digo postal" />
                <FunctionField
                    label="Envío a agencia"
                    render={
                        (rec) => (
                            rec?.shippingData?.shippingType === SHIPPING_TYPES.pickupAtNearestAgency
                                ? <DoneIcon />
                                : <ClearIcon />
                        )
                    }
                />
                <TextInput source="shippingData.address" label="Direcci&oacute;n" />
                <ShippingDataFloorInput />
                <BooleanInput
                    source="shippingData.useTelegramForTracking"
                    label="Recepci&oacute;n de seguimiento en Telegram"
                />
            </FormTab>
            <FormTab label="Productos">
                <ArrayField source="products">
                    <Datagrid>
                        <TextField label="Build Id" source="item.buildId" sortable={false} />
                        <TextField label="Nombre" source="item.productShadow.name" sortable={false} />
                        <TextField label="C&oacute;digo" source="item.productShadow.code" sortable={false} />
                        <NumberField
                            label="Precio especial"
                            source="item.price.special"
                            locales={cartHelper.formaterPesosLocales}
                            options={{
                                style: cartHelper.formaterPesosStyle,
                                currency: cartHelper.formaterPesosCurrency,
                            }}
                            sortable={false}
                        />
                        <NumberField
                            label="Precio lista"
                            source="item.price.list"
                            locales={cartHelper.formaterPesosLocales}
                            options={{
                                style: cartHelper.formaterPesosStyle,
                                currency: cartHelper.formaterPesosCurrency,
                            }}
                            sortable={false}
                        />
                        <NumberField label="Cantidad" source="item.quantity" sortable={false} />
                        <TextField source="serialNumber" label="N&uacute;mero de Serie" sortable={false} />
                        <FunctionField render={
                            (record) => (
                                <ChangeSerialNumberButton
                                    record={record}
                                    // eslint-disable-next-line react/destructuring-assignment
                                    orderId={props.id}
                                    productParameterToSetType="serialNumber"
                                />
                            )
                        }
                        />
                        <TextField source="productBillId" label="FC Proveedor" sortable={false} />
                        <FunctionField render={
                            (record) => (
                                <ChangeSerialNumberButton
                                    record={record}
                                    // eslint-disable-next-line react/destructuring-assignment
                                    orderId={props.id}
                                    productParameterToSetType="productBillId"
                                />
                            )
                        }
                        />
                        <FunctionField render={
                            (record) => <ShowButton record={{ id: record.item.product }} basePath="/products" />
                        }
                        />
                    </Datagrid>
                </ArrayField>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default OrderEdit;
