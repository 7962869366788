import React from 'react';
import { Labeled, NumberField, TextField } from 'react-admin';
import cartHelper from '../../helpers/cartHelper';

export default ({ record, ...rest }) => {
    const all = { record, ...rest };
    if (!record?.appliedCoupon) return null;

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Labeled label="C&oacute;digo de descuento aplicado">
                <TextField source="appliedCoupon.code" {...all} />
            </Labeled>
            <Labeled label="Porcentaje de descuento aplicado">
                <NumberField
                    source="appliedCoupon.discount_percentage"
                    min="0"
                    max="100"
                    {...all}
                />
            </Labeled>
            <Labeled label="Descuento aplicado">
                <NumberField
                    source="productsPriceDiscount"
                    min="0"
                    locales={cartHelper.formaterPesosLocales}
                    options={{
                        style: cartHelper.formaterPesosStyle,
                        currency: cartHelper.formaterPesosCurrency,
                    }}
                    {...all}
                />
            </Labeled>
        </div>
    );
};
