import { fetchUtils } from 'ra-core';
import { stringify } from 'query-string';
import injectImagesInPayload from './imagesInjection';

const dataProvider = (apiUrl) => {
    const httpClient = (url, opt) => {
        const options = {
            ...opt,
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
            credentials: 'include',
        };

        return fetchUtils.fetchJson(url, options);
    };

    return {
        getList: (resource, params) => {
            const { page, perPage } = params.pagination || {};
            const { field, order } = params.sort || {};
            const query = {
                page,
                perPage,
                sort: JSON.stringify({ field, order }),
                filter: JSON.stringify(params.filter),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;

            return httpClient(url).then(({ json }) => json);
        },

        getOne: (resource, params) => httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),
        getMany: (resource, params) => {
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            return httpClient(url).then(({ json }) => json);
        },

        getManyReference: (resource, params) => {
            const { page, perPage } = params.pagination || {};
            const { field, order } = params.sort || {};
            const query = {
                page,
                perPage,
                sort: JSON.stringify({ field, order }),
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;

            return httpClient(url).then(({ json }) => json);
        },

        update: (resource, params) => injectImagesInPayload(resource, params)
            .then((readyParams) => httpClient(`${apiUrl}/${resource}/${readyParams.id}`, {
                method: 'PUT',
                body: JSON.stringify(readyParams.data),
            }))
            .then(({ json }) => ({ data: json })),

        updateMany: (resource, params) => {
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json }));
        },

        create: (resource, params) => injectImagesInPayload(resource, params)
            .then((readyParams) => httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(readyParams.data),
            })).then(({ json }) => ({ data: json })),
        delete: (resource, params) => httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

        deleteMany: (resource, params) => {
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                method: 'DELETE',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json }));
        },
        changePassword: (resource, params) => {
            const { password } = params;
            return httpClient(`${apiUrl}/users/${params.id}/password`, {
                method: 'PATCH',
                body: JSON.stringify({ password }),
            }).then(({ json }) => ({ data: json.password }));
        },
        dashboard: () => httpClient(`${apiUrl}/dashboard`, {
            method: 'GET',
        }).then(({ json }) => ({ data: json })),

        requestShipping: (params) => httpClient(`${apiUrl}/orders/${params.orderId}/shipping`, {
            method: 'POST',
            body: JSON.stringify({}),
        }).then(({ json }) => ({ data: json })),
        setProductParameter: (resource, params) => httpClient(`${apiUrl}/orders/${params.id}/products/${params.productId}/${params.productParameterToSetType}`, {
            method: 'PUT',
            body: JSON.stringify(params),
        }).then(({ json }) => ({ data: json })),
        viaCargoTest: (resource, params) => httpClient(`${apiUrl}/shipping/test`, {
            method: 'POST',
            body: JSON.stringify({ smokeTestMode: params.mode }),
        }).then(({ json }) => ({ data: json })),
        generateAiDescription: (params) => httpClient(`${apiUrl}/products/ai/description/${params.id}`, {
            method: 'POST',
        }).then(({ json }) => ({ data: json })),
    };
};

export default dataProvider;
